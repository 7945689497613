<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="quesDialogTitle"></span>
    </div>
    <!-- editData：{{editData}}
    <hr>
    fileListArray：{{fileListArray}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px" style="width: 98%">
      <el-row>
        <el-form-item label="标题：" prop="title">
          <el-input v-model="formData.title" placeholder="请输入标题"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="6" style>
          <el-form-item label="时间限制：">
            <el-input style="width: 69px;" placeholder="1" v-model="formData.time_limit">
              <i slot="suffix" style="font-size: 12px;padding-right: 10px;color: #333333;">/S</i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="内存限制：">
            <el-input style="width: 128px;" placeholder="128" v-model="formData.memory_limit">
              <i slot="suffix" style="font-size: 12px;padding-right: 10px;color: #333333;">/MByte</i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="特判题目：">
            <el-checkbox v-model="formData.spj"></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="题目描述：" prop="description">
          <Tinymce v-model="formData.description" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="输入描述：">
          <Tinymce v-model="formData.input" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="输出描述：" prop="output">
          <Tinymce v-model="formData.output" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="样例输入：">
            <el-input type="textarea" :rows="5" placeholder="输入描述" v-model="formData.sample_input"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="样例输出：" prop="sample_output">
            <el-input type="textarea" :rows="5" placeholder="输出描述" v-model="formData.sample_output"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!this.editData">
        <el-col :span="12">
          <el-form-item label="测试输入：">
            <el-input type="textarea" :rows="5" placeholder="输入描述" v-model="formData.test_input"></el-input>
            <p class="test-tit">*更多测试数据，请在题目添加完成后补充</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="测试输出：">
            <el-input type="textarea" :rows="5" placeholder="输出描述" v-model="formData.test_output"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="提示：">
          <Tinymce v-model="formData.hint" width="100%" :height="350" v-if="show" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="标签：">
          <!-- <el-tag
            v-for="(tag, index) in tagList"
            :key="index"
            closable
            type="info"
            @close="tagDel(index)"
          >{{tag.title}}</el-tag>
          <el-autocomplete
            v-model="formData.tagName"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入标签名"
            @select="handleSelect"
            @keyup.enter.native="addTagList"
          ></el-autocomplete> -->
          <el-cascader
            v-model="formData.tagSet"
            :options="tagTreeData"
            :props="props"
            placeholder="请选择所属标签"
            @change="forceUpdate"
            clearable></el-cascader>
        </el-form-item>
      </el-row>

<!--      <el-form-item label="题解视频：">-->
<!--        <div style="display: flex;align-items: start">-->
<!--        <div style="display: flex;">-->
<!--          <el-upload-->
<!--            :auto-upload="false"-->
<!--            :before-remove="beforeRemove"-->
<!--            :file-list="fileListArray"-->
<!--            :on-change="onFileChange"-->
<!--            :headers= "headers"-->
<!--            :on-success="handleAvatarVideoSuccess"-->
<!--            ref="uploadfile"-->
<!--            :action="uploadVideoUrl"-->
<!--            name="file"-->
<!--          >-->
<!--            <el-button size="small" slot="trigger" type="primary">选取视频</el-button>-->
<!--            <el-button-->
<!--              @click="uploadCheck()"-->
<!--              size="small"-->
<!--              style="margin-left: 10px;"-->
<!--              type="success"-->
<!--            >上传到服务器</el-button>-->
<!--            <span style="margin-left: 15px;">（文件支持mp4、rmvb、flv、avi、wmv格式）</span>-->
<!--          </el-upload>-->

<!--        </div>-->
<!--        <el-button v-if="canPreview" size="small" class="previewvideo" @click="priviewVideo" slot="trigger" type="primary">预览题解视频</el-button>-->
<!--        </div>-->
<!--      </el-form-item>-->
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="saveForm" class="dialog-btn">保 存</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import Tinymce from '@/components/newTinymce'
import { getTags, addTags } from '@/api/research/tag'
import { getVideo } from '@/api/research/question'
import getTag from '@/mixins/getTag'
import { getToken } from '@/utils/auth'
import { uploadCheck } from '@/api/video/videomanage'
import SparkMD5 from "spark-md5"
import { getBjyUrl } from '@/api/video/videomanage'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  mixins: [getTag],
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  components: {
    Tinymce
  },
  data () {
    let descTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请输入题目描述'))
      } else {
        callback()
      }
    }
    let outputTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请输入输出描述'))
      } else {
        callback()
      }
    }
    return {
      quesDialogTitle: '',
      formData: {
        title: '', // 问题标题
        time_limit: '', // 时间限制
        memory_limit: '', // 内存限制
        description: '', // 问题描述
        input: '', // 输入
        output: '', // 输出
        sample_input: '', // 样例输入
        sample_output: '', // 样例输出
        test_input: '', // 测试输入
        test_output: '', // 测试输出
        hint: '', // 提示
        tagName: '' // 标签名
      },
       props: {
        multiple: true,
        // checkStrictly: true,
        value: 'id',
        label: 'title'
      },
      tagList: [], // 问题所属标签列表
      rules: {
        title: [{ required: true, message: '请输入题目标题', trigger: 'blur' }],
        description: [{ required: true, validator: descTextVal, trigger: 'blur' }],
        output: [{ required: true, validator: outputTextVal, trigger: 'blur' }],
        sample_output: [{ required: true, message: '请输入题目样例输出', trigger: 'blur' }]
      },
      tag_ids: '', // 标签id
      restaurants: [],
      timeout: null,
      fileListArray: [],
      headers: {},
      uploadVideoUrl: process.env.NODE_ENV === 'development' ? '/video/file/upload' : process.env.VUE_APP_VIDEO_API_ADDRESS + '/file/upload',
      hash: "",
      currentVideoId:"",
      canPreview:false
    }
  },
  // mounted () {
  //   this.getTagList()
  // },
  methods: {
    openDialog () {
      this.headers.Authorization = getToken()
      this.canPreview = false
      this.tagList = []
      this.formData = {}
      this.fileListArray = []
      if (this.editData === null) {
        this.quesDialogTitle = '新增题目'
      } else {
        this.quesDialogTitle = '编辑题目'
        this.formData = Object.assign({}, this.editData)
        if(this.editData.spj === null || this.editData.spj === '0') {
          this.formData.spj = false
        }else if (this.editData.spj === '1') {
          this.formData.spj = true
        }
        console.log('this.formData.spj '+this.formData.spj)
        const tagGroupOrderList = this.tagGroupList
        const tagList = this.formData.tagList
        const tagSet = []
        tagGroupOrderList.forEach((item, index) => {
          tagList.forEach((ele, idx) => {
            if (item.id === ele.groupId) {
              tagSet.push([
                ele.groupId, ele.id
              ])
            }
          })
        })
        this.formData.tagSet = tagSet
        this.getVideo()
      }
    },

    getVideo () {
      getVideo({
        problemId: this.editData.problem_id
      }).then(res => {
        if (Boolean(res.body)) {
          this.canPreview = true
          this.currentVideoId = res.body
        }else {
          this.canPreview = false
        }
      })
    },
    priviewVideo() {
      getBjyUrl({
        fileId: this.currentVideoId
      }).then(res => {
        if (res.code === 0) {
          console.log('res', res.data)
          window.open(res.data, '_blank')
        }
      })
    },
    beforeRemove (file, fileList) {
      return  this.$confirm(`确定移除 ${file.name}？`).then(()=>{
        this.fileListArray = []
        return true
      })
    },

    onFileChange (file, fileList) {
      let arr = file.name.split('.')
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase()
        if (suffixName !== 'mp4' && suffixName !== 'rmvb'&& suffixName !== 'flv'&& suffixName !== 'avi'&& suffixName !== 'wmv') {
          this.$message.error('文件类型错误，请重新上传！')
          this.fileListArray = []
          return false
        }
      }
      this.fileListArray = fileList.slice(-1);
      let _this = this
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw

      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        _this.hash = spark.end()
        console.log('hash', _this.hash)
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024*1024))
    },

    handleAvatarVideoSuccess (res,file) {
      if (res && res.code === 0) {
        this.formData.video = res.data
        window.$msg('上传成功')
        this.getVideo()
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    async uploadCheck () {
      if(this.fileListArray.length === 0) {
        $msg("请先选择视频在上传",2)
        return false
      }
      let _this = this
      const res = await uploadCheck({
        hash: _this.hash
      })
      if(res.code === 0){
        if(res.data === "") {
          this.$refs.uploadfile.submit()
        }else {
          this.formData.video = res.data
          this.currentVideoId = res.data
          this.canPreview = true
          window.$msg('上传成功')
        }
      }
    },

    // close
    close () {
      this.$confirm('确定退出当前编辑吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('close')
        })
        .catch(() => {
          return false
        })
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    // add-save
    saveForm () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (!this.formData.tagSet) {
          } else {
            let tag_ids = ''
            this.formData.tagSet.forEach((ele, idx) => {
              tag_ids += ele[1] + ','
            });
            this.tag_ids = tag_ids.substring(0, tag_ids.length - 1)
          }
          let formData = {
            problem_id: this.formData.problem_id || '',
            title: this.formData.title,
            time_limit: this.formData.time_limit || 1,
            memory_limit: this.formData.memory_limit || 128,
            description: this.formData.description,
            input: this.formData.input,
            output: this.formData.output,
            sample_input: this.formData.sample_input,
            sample_output: this.formData.sample_output,
            test_input: this.formData.test_input,
            test_output: this.formData.test_output,
            spj:this.formData.spj ? '1' : '0',
            hint: this.formData.hint,
            tag_ids: this.tag_ids,
          }
          if (this.editData === null) {
            this.$emit('saveForm', { ...formData })
          } else {
            this.$emit('editForm', { ...formData })
          }
        } else {
          window.$msg('请完成必填项', 1)
          return false
        }
      })
    },

    // 标签删除
    tagDel (index) {
      this.$confirm('是否删除当前标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.tagList.splice(index, 1)
          window.$msg('操作成功')
        })
        .catch(() => {
          return false
        })
    },

    // 获取启用的标签
    // async getTagList () {
    //   const res = await getTags()
    //   let restaurants = res.body
    //   for (let i = 0; i < restaurants.length; i += 1) {
    //     restaurants[i].value = restaurants[i].title
    //   }
    //   this.restaurants = restaurants
    // },

    querySearchAsync (queryString, cb) {
      let restaurants = this.restaurants
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 50 * Math.random())
    },
    createStateFilter (queryString) {
      return state => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect (item) {
      this.$nextTick(() => {
        this.tagList.push(item)
        this.formData.tagName = ''
      })
    },

    // 添加标签
    async addTagList () {
      if (this.formData.tagName) {
        const res = await addTags({
          title: this.formData.tagName
        })
        window.$msg('标签添加成功')
        this.tagList.push(res.body)
      }
      this.formData.tagName = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

/deep/ .el-dialog {
  min-width: 888px;
}
  /deep/ .previewvideo {
    height: 30px;
    margin-left: 30px;
    margin-top: 5px;
  }
</style>
