<template>
  <el-row class="table">
    <span class="title">问题管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入搜索标题、ID关键字"
        v-model.trim="queryData.inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="queryData.select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="问题标题" value="1"></el-option>
          <el-option label="问题id" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select
        v-model="queryData.seleTag"
        multiple
        placeholder="选择标签"
        class="tb-smselect"
        @change="handleSeleTag"
      >
        <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="queryData.seleState" placeholder="选择状态" class="tb-smselect" @change="handleSeleState">
        <el-option v-for="item in queState" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button" v-has="'question:ManageAdd'">新增题目</el-button>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      @selection-change="handleSeleChange"
      :row-key="getKey"
    >
      <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
      <el-table-column label="ID" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.problem_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="问题标题" align="left" min-width="130">
        <template slot-scope="scope">
          <span
            class="title-link"
            @click="quesDetail(scope.row)"
            style="line-height: 25px;"
          >{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="测试用例" align="center">
        <template slot-scope="scope">
          <span class="lookover" @click="lookover(scope.row)" v-has="'question:ManageTest'">查看({{ scope.row.test_case_num }})</span>
        </template>
      </el-table-column>
      <el-table-column label="提交记录" align="center">
        <template slot-scope="scope">
          <span
            class="pointer"
            @click="subRecord(scope.row)"
          >{{ scope.row.right_num }}/{{ scope.row.total_num }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="是否有题解视频" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <span v-if="Boolean(scope.row.video)" style="color: #4DA54D">是</span>-->
<!--          <span v-else style="color: #f56c6c">否</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="标签" align="center">
        <template slot-scope="scope">
          <template v-if="!scope.row.tagList || scope.row.tagList.length === 0">
            <span>无</span>
          </template>
          <template v-else>
            <span v-for="item of scope.row.tagList" :key="item.id">
              <template v-if="Boolean(item.groupInfo)">
                <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+item.groupInfo.color"></span>
              </template>
              <template v-else>
                <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
              </template>
            </span>
          </template>
        </template>
        <!-- <template slot-scope="scope">
          <el-row v-if="scope.row.tag_titles">
            <span
              class="tag"
              v-for="(item, index) of jointArr(scope.row.tag_titles)"
              :key="index"
            >{{ item }}</span>
          </el-row>
          <el-row v-else>无</el-row>
        </template> -->
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span
            v-text="getState(scope.row.defunct)"
            :style="'color'+':'+getStateColor(scope.row.defunct)"
          ></span>
        </template>
      </el-table-column>
      <el-table-column label="特判" align="center">
        <template slot-scope="scope">
          <span style="color: #4DA54D" v-if="scope.row.spj === '1'">是</span>
          <span style="color: #CE433F" v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click.native="edit(scope.row)" v-has="'question:ManageEdit'">编辑</el-button>
          <el-button
            size="small"
            type="danger"
            plain
            @click.native="enableQues(scope.row)"
            style="margin-left: 10px;"
            v-has="'question:ManageStop'"
            v-if="scope.row.defunct === 'N'"
          >停用</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click.native="enableQues(scope.row)"
            style="margin-left: 10px;"
            v-else
          >启用</el-button>
          <el-button size="small" type="primary" plain @click.native="deleteQues(scope.row)" v-has="'question:ManageDel'">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dropdown trigger="click" class="tb-dropdown">
      <el-button type="info" size="medium">
        更多操作
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="enableAccount">
          <i class="el-icon-video-play"></i>批量启用
        </el-dropdown-item>
        <el-dropdown-item @click.native="disabledAccount">
          <i class="el-icon-video-pause"></i>批量停用
        </el-dropdown-item>
        <el-dropdown-item @click.native="deleteAccount">
          <i class="el-icon-delete"></i>批量删除
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 添加题目 -->
    <add-question
      :show="addTopicDialog"
      :editData="editData"
      @saveForm="saveForm"
      @editForm="editForm"
      @close="addTopicDialog=false,editData=null"
    ></add-question>

    <!-- 查看测试用例 -->
    <test-case :show="testCaseDialog" :pID="pID" @close="testCaseDialog=false"></test-case>
  </el-row>
</template>

<script>
import addQuestion from '@/components/research/question-manage/addQuestion'
import TestCase from '@/components/research/question-manage/testCase'
import { getQuestionList, appendQuestion, enableQues, updateQuestion } from '@/api/research/question'
import { getTags } from '@/api/research/tag'

export default {
  components: {
    addQuestion,
    TestCase
  },
  data () {
    return {
      tagList: [], // 已启用的标签列表
      pID: 0,
      queState: [
        {
          label: '已停用',
          value: 'Y'
        },
        {
          label: '已启用',
          value: 'N'
        }
      ],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      addTopicDialog: false,
      editData: null,
      multipleSelection: [],
      testCaseDialog: false,
      queryData: {
        inputVal: '',
        select: '1',
        seleTag: '',
        seleState: 'N',
        tagId: ''
      }
    }
  },
  created () {
    this.getQuestionList()
    this.getTags()
  },
  methods: {
    getKey (row) {
      return row.problem_id
    },

    // 获取问题列表
    async getQuestionList () {
      const res = await getQuestionList({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        problem_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
        title: this.queryData.select === '1' ? this.queryData.inputVal : '',
        tag_ids: this.queryData.tagId,
        defunct: this.queryData.seleState
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 选择标签
    handleSeleTag (tagId) {
      var problemIds = ''
      problemIds = tagId[0]
      for (var i = 1; i < tagId.length; i++) {
        problemIds = problemIds + ',' + tagId[i]
      }
      if (problemIds === undefined) {
        this.queryData.tagId = null
      } else {
        this.queryData.tagId = problemIds
      }
    },

    // 状态
    handleSeleState (val) {
      this.seleState = val
    },

    // 获取启用得标签
    async getTags () {
      const res = await getTags(
        {
          pageNum: 1,
          pageSize: 10000,
          businessType:'oj'
        }
      )
      this.tagList = res.body
    },

    jointArr (row) {
      let tagArr = []
      tagArr = row.split(',')
      return tagArr
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuestionList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuestionList()
    },

    // 搜索
    search () {
      if (this.queryData.select === '2') {
        let reg = /^[0-9]*$/
        if (!reg.test(this.queryData.inputVal)) {
          window.$msg('请输入正确的id', 2)
          return false
        }
      }
      this.pagination.currentPage = 1
      this.getQuestionList()
    },

    // 重置按钮
    reset () {
      this.queryData = {
        inputVal: '',
        select: '1',
        seleTag: '',
        seleState: '',
        tagId: ''
      }
    },

    // 新增题目
    add () {
      this.editData = null
      this.addTopicDialog = true
    },

    getState (state) {
      switch (state) {
        case 'Y':
          return '已停用'
        case 'N':
          return '已启用'
        default:
          return '未知状态'
      }
    },
    getStateColor (state) {
      switch (state) {
        case 'Y':
          return '#CE433F'
        case 'N':
          return '#4DA54D'
        default:
          return '#F5C319'
      }
    },

    // 编辑
    edit (row) {
      let editData = row
      this.editData = editData
      this.addTopicDialog = true
    },

    // 启/停 用问题
    async enableQues (row) {
      if (row.defunct === 'N') {
        await enableQues([{
          problem_id: row.problem_id,
          defunct: 'Y'
        }])
        window.$msg('停用成功')
        this.getQuestionList()
      } else {
        await enableQues([{
          problem_id: row.problem_id,
          defunct: 'N'
        }])
        window.$msg('启用成功')
        this.getQuestionList()
      }
    },

    // table 选中项
    handleSeleChange (val) {
      this.multipleSelection = val
    },

    // 删除问题
    deleteQues (row) {
      this.$confirm('是否删除当前问题', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await enableQues([{
            problem_id: row.problem_id,
            is_delete: 1
          }])
          window.$msg('删除成功')
          this.getQuestionList()
        })
        .catch(() => {
          return false
        })
    },

    // 批量启用问题
    async enableAccount () {
      if (this.multipleSelection.length !== 0) {
        let enableList = []
        this.multipleSelection.map((item, index) => {
          enableList.push({ problem_id: item.problem_id, defunct: 'N' })
        })
        await enableQues(enableList)
        window.$msg('启用成功')
        this.getQuestionList()
      } else {
        window.$msg('请先选择问题', 2)
      }
    },

    // 批量停用问题
    async disabledAccount () {
      if (this.multipleSelection.length !== 0) {
        let disabledList = []
        this.multipleSelection.map((item, index) => {
          disabledList.push({ problem_id: item.problem_id, defunct: 'Y' })
        })
        await enableQues(disabledList)
        window.$msg('停用成功')
        this.getQuestionList()
      } else {
        window.$msg('请先选择问题', 2)
      }
    },

    // 批量删除问题
    deleteAccount () {
      if (this.multipleSelection.length !== 0) {
        this.$confirm('是否删除当前问题', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            let delList = []
            this.multipleSelection.map((item, index) => {
              delList.push({ problem_id: item.problem_id, is_delete: 1 })
            })
            await enableQues(delList)
            window.$msg('删除成功')
            this.getQuestionList()
          })
          .catch(() => {
            return false
          })
      } else {
        window.$msg('请先选择问题', 2)
      }
    },

    // add-save
    async saveForm (data) {
      await appendQuestion({ ...data })
      window.$msg('新增成功')
      this.addTopicDialog = false
      this.getQuestionList()
    },

    // edit-save
    async editForm (data) {
      await updateQuestion({ ...data })
      window.$msg('编辑成功')
      this.addTopicDialog = false
      this.getQuestionList()
    },

    // 携带问题的id标识，跳转提交记录页面
    subRecord (row) {
      if (row.total_num === 0) {
        window.$msg('暂无提交记录', 2)
      } else {
        this.$router.push({
          path: 'question-records',
          query: {
            problem_id: row.problem_id
          }
        })
      }
    },

    // 携带问题的id标识，跳转题目详情页面
    quesDetail (row) {
      let routeData = this.$router.resolve({
        path: '/research/question-details',
        query: {
          problemID: row.problem_id
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 查看测试用例
    lookover (row) {
      this.pID = row.problem_id
      this.testCaseDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.lookover {
  color: #eb9316;
  cursor: pointer;
}

.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 8px;
}
</style>
