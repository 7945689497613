var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.quesDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          staticStyle: { width: "98%" },
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题：", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间限制：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "69px" },
                          attrs: { placeholder: "1" },
                          model: {
                            value: _vm.formData.time_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "time_limit", $$v)
                            },
                            expression: "formData.time_limit",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "padding-right": "10px",
                                color: "#333333",
                              },
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            },
                            [_vm._v("/S")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "内存限制：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "128px" },
                          attrs: { placeholder: "128" },
                          model: {
                            value: _vm.formData.memory_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "memory_limit", $$v)
                            },
                            expression: "formData.memory_limit",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "padding-right": "10px",
                                color: "#333333",
                              },
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            },
                            [_vm._v("/MByte")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "特判题目：" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.formData.spj,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "spj", $$v)
                          },
                          expression: "formData.spj",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "题目描述：", prop: "description" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "输入描述：" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.input,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "input", $$v)
                          },
                          expression: "formData.input",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "输出描述：", prop: "output" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.output,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "output", $$v)
                          },
                          expression: "formData.output",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "样例输入：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          placeholder: "输入描述",
                        },
                        model: {
                          value: _vm.formData.sample_input,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sample_input", $$v)
                          },
                          expression: "formData.sample_input",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "样例输出：", prop: "sample_output" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          placeholder: "输出描述",
                        },
                        model: {
                          value: _vm.formData.sample_output,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sample_output", $$v)
                          },
                          expression: "formData.sample_output",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !this.editData
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "测试输入：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "输入描述",
                            },
                            model: {
                              value: _vm.formData.test_input,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "test_input", $$v)
                              },
                              expression: "formData.test_input",
                            },
                          }),
                          _c("p", { staticClass: "test-tit" }, [
                            _vm._v("*更多测试数据，请在题目添加完成后补充"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "测试输出：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "输出描述",
                            },
                            model: {
                              value: _vm.formData.test_output,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "test_output", $$v)
                              },
                              expression: "formData.test_output",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "提示：" } },
                [
                  _vm.show
                    ? _c("Tinymce", {
                        attrs: { width: "100%", height: 350 },
                        model: {
                          value: _vm.formData.hint,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "hint", $$v)
                          },
                          expression: "formData.hint",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签：" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.tagTreeData,
                      props: _vm.props,
                      placeholder: "请选择所属标签",
                      clearable: "",
                    },
                    on: { change: _vm.forceUpdate },
                    model: {
                      value: _vm.formData.tagSet,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "tagSet", $$v)
                      },
                      expression: "formData.tagSet",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveForm },
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }