var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("问题管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-input",
            {
              staticClass: "tb-search-input",
              attrs: { placeholder: "输入搜索标题、ID关键字" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.queryData.inputVal,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData,
                    "inputVal",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.inputVal",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "prepend", placeholder: "请选择" },
                  slot: "prepend",
                  model: {
                    value: _vm.queryData.select,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "select", $$v)
                    },
                    expression: "queryData.select",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "问题标题", value: "1" } }),
                  _c("el-option", { attrs: { label: "问题id", value: "2" } }),
                ],
                1
              ),
              _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { multiple: "", placeholder: "选择标签" },
              on: { change: _vm.handleSeleTag },
              model: {
                value: _vm.queryData.seleTag,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "seleTag", $$v)
                },
                expression: "queryData.seleTag",
              },
            },
            _vm._l(_vm.tagList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "选择状态" },
              on: { change: _vm.handleSeleState },
              model: {
                value: _vm.queryData.seleState,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "seleState", $$v)
                },
                expression: "queryData.seleState",
              },
            },
            _vm._l(_vm.queState, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜 索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "question:ManageAdd",
                  expression: "'question:ManageAdd'",
                },
              ],
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增题目")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": _vm.getKey,
          },
          on: { "selection-change": _vm.handleSeleChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "70",
              align: "center",
              "reserve-selection": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "ID", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.problem_id))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "问题标题", align: "left", "min-width": "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "title-link",
                        staticStyle: { "line-height": "25px" },
                        on: {
                          click: function ($event) {
                            return _vm.quesDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.title))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "测试用例", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "question:ManageTest",
                            expression: "'question:ManageTest'",
                          },
                        ],
                        staticClass: "lookover",
                        on: {
                          click: function ($event) {
                            return _vm.lookover(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看(" + _vm._s(scope.row.test_case_num) + ")")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提交记录", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.subRecord(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.right_num) +
                            "/" +
                            _vm._s(scope.row.total_num)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "标签", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.tagList || scope.row.tagList.length === 0
                      ? [_c("span", [_vm._v("无")])]
                      : _vm._l(scope.row.tagList, function (item) {
                          return _c(
                            "span",
                            { key: item.id },
                            [
                              Boolean(item.groupInfo)
                                ? [
                                    _c("span", {
                                      staticClass: "tag",
                                      style:
                                        "backgroundColor" +
                                        ":" +
                                        item.groupInfo.color,
                                      domProps: {
                                        textContent: _vm._s(item.title),
                                      },
                                    }),
                                  ]
                                : [
                                    _c("span", {
                                      staticClass: "tag",
                                      style: "backgroundColor" + ":" + "#ccc",
                                      domProps: {
                                        textContent: _vm._s(item.title),
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      style:
                        "color" + ":" + _vm.getStateColor(scope.row.defunct),
                      domProps: {
                        textContent: _vm._s(_vm.getState(scope.row.defunct)),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "特判", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.spj === "1"
                      ? _c("span", { staticStyle: { color: "#4DA54D" } }, [
                          _vm._v("是"),
                        ])
                      : _c("span", { staticStyle: { color: "#CE433F" } }, [
                          _vm._v("否"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "question:ManageEdit",
                            expression: "'question:ManageEdit'",
                          },
                        ],
                        attrs: { size: "small", type: "primary", plain: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    scope.row.defunct === "N"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "question:ManageStop",
                                expression: "'question:ManageStop'",
                              },
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small", type: "danger", plain: "" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.enableQues(scope.row)
                              },
                            },
                          },
                          [_vm._v("停用")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              plain: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.enableQues(scope.row)
                              },
                            },
                          },
                          [_vm._v("启用")]
                        ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "question:ManageDel",
                            expression: "'question:ManageDel'",
                          },
                        ],
                        attrs: { size: "small", type: "primary", plain: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.deleteQues(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dropdown",
        { staticClass: "tb-dropdown", attrs: { trigger: "click" } },
        [
          _c("el-button", { attrs: { type: "info", size: "medium" } }, [
            _vm._v(" 更多操作 "),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.enableAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-video-play" }),
                  _vm._v("批量启用 "),
                ]
              ),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.disabledAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-video-pause" }),
                  _vm._v("批量停用 "),
                ]
              ),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deleteAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-delete" }),
                  _vm._v("批量删除 "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-question", {
        attrs: { show: _vm.addTopicDialog, editData: _vm.editData },
        on: {
          saveForm: _vm.saveForm,
          editForm: _vm.editForm,
          close: function ($event) {
            ;(_vm.addTopicDialog = false), (_vm.editData = null)
          },
        },
      }),
      _c("test-case", {
        attrs: { show: _vm.testCaseDialog, pID: _vm.pID },
        on: {
          close: function ($event) {
            _vm.testCaseDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }